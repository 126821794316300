import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import Circular from './assets/images/circular.svg'
import {
  Container,
  DialogOk,
  InsureLoading,
  LoadingGlobalIndicator,
} from './components'
import { RoutePath, SwitchPages } from './routes'
import { useAppStore } from './store'
import { clearError } from './store/app'
import { CheckPageId } from './routes/route-before'
import { PageIdContextProvider } from './providers/page-id-context'
import { gt } from 'semver'
import DialogRefresh from './components/Dialog/DialogRefresh'
import { useLocation } from 'react-router-dom'
import { controller } from './pages/OldMain/Main'
import { ErrorBoundary } from 'react-error-boundary'
import { AxiosError } from 'axios'
import { trackErrorLogs } from './utils'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from './store/settings'

function App() {
  const location = useLocation()

  const [error, setError] = useState<AxiosError>()

  const bgStyle = useMemo(() => {
    const path = location.pathname

    switch (path) {
      case RoutePath.Home:
        return 'main'
      case RoutePath.OldHome:
        return 'old_main'
      case RoutePath.History:
      case RoutePath.Benefit_detail:
        return 'sub'
      case RoutePath.Benefit_popular:
        return 'sub2'
      default:
        return undefined
    }
  }, [location])

  const handleError = useCallback((error: AxiosError) => {
    setError((prev) => {
      if (!prev) return error
      if (prev.response?.status !== error.response?.status) return error
    })
    // useErrorBoundary: true 설정된 에러 트래킹
    trackErrorLogs(error, 'tscore:error:errorBoundary')
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onPause', () => {
      if (controller.signal) {
        controller.abort()
      }
    })
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onRefresh', () => {
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', () => {
      const bodyEl = document.querySelector('body') as HTMLBodyElement
      let element: HTMLElement | null
      element = document.createElement('div')
      element.style.cssText = 'position: absolute; left: -10x; width: 1px; height: 1px; background: rgba(0, 0, 0, 0.01)'
      bodyEl.appendChild(element)
      setTimeout(() => (element?.remove()), 1000)
    })
  }, [])

  return (
    <>
      <InsureLoading/>
      <LoadingGlobalIndicator
        rootElementSelector={'#root'}
        imageSrc={Circular}
        useDelay={true}
      />

      <PageIdContextProvider>
        <ErrorBoundary
          onError={(error) => handleError(error as AxiosError)}
          fallback={<ErrorNotifier error={error}/>}
        >
        <CheckPageId>
          <Container className={bgStyle}>
            <SwitchPages/>
          </Container>
        </CheckPageId>
        </ErrorBoundary>
      </PageIdContextProvider>
    </>
  )
}

export function ErrorNotifier({error}: {error: AxiosError | undefined}) {
  const store = useAppStore()
  const dialogManager = useDialogManager()
  const isMockEnabled = useSelector(selectSettingsMockEnabled)
  const [dialogId, setDialogId] = useState('')
  // 동일한 에러가 여러번 dispatch 됐을때 무시하도록 error.code만 확인.
  const errorCode = error?.response?.status

  useEffect(() => {
      if (error) {
        let message: ReactNode

        switch (errorCode) {
          case 401:
            message = <>사용자 인증정보가 변경되었습니다.<br/>잠시 후 다시 시도해주세요.</>
            break
          default:
            message = <> 일시적으로 서비스를<br/> 이용할 수 없습니다.<br/> <p className="sub">잠시 후 다시 시도해주세요.</p></>
            break
        }

        if (process.env.REACT_APP_BUILD_ENV !== 'PRD' && process.env.REACT_APP_BUILD_ENV !== 'STG') {
          message = (
            <>
              {message}<br/>
              <pre className="type_left type_small type_break_all type_preserve-wrap">
              <>
              <div className="type_center">
                <>
                  {error.code}{error?.message ? <><br/>({error.message})</> : ''}<br/>
                </>
              </div>
                {error.response?.data && JSON.stringify(error.response?.data, null, 2)}
                </>
            </pre>
            </>
          )
        }

        const close = () => {
          TmapApp.onBackKeyPressed()
          store.dispatch(clearError())
        }

        const refresh = () => {
          window.location.reload()
          store.dispatch(clearError())
        }

        if (gt(TmapApp.env.appVersion, '8.13.0') && window.location.pathname === '/web') {
          dialogManager.showDialog(
            {
              component: DialogRefresh,
              props: {
                title: message,
                onOk: refresh,
              },
            },
            {
              useHistory: false,
            },
          )
          setDialogId(dialogManager.dialogItemList[0].id)
        } else {
          /**
           * 9.0 이전 버전 (8.13.0) 웹은 메인 링크를 통해 웹뷰를 열고/닫는 형태이기 때문에
           * 저버전에서는 기존 팝업 형태를 유지
           */
          dialogManager.showDialog(
            {
              component: DialogOk,
              props: {
                title: message,
                onOk: close,
              },
            },
            {
              useCloseOnOutsideClick: false,
              useHistory: false,
              onClosedByDismiss: close,
            },
          )
          setDialogId(dialogManager.dialogItemList[0].id)
        }
      }
  }, [dialogManager, store, errorCode, error])

  useEffect(() => {
    if (JSON.parse(isMockEnabled as string) && dialogId) {
      dialogManager.closeDialog(dialogId)
    }
  }, [isMockEnabled,dialogManager, dialogId])

  return null
}

export default App
